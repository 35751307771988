import { httpSnackbar, httpSilent } from '@/plugins/axios'

export default class ManualWebClient {
  http = httpSnackbar;

  /**
   * Construtor
   * @param {boolean} silent
   */
  constructor(silent = false) {
    if (silent) this.http = httpSilent;
  }

  /**
   * Obtem a listagem de manuais.
   * @returns {Promise<any[]>}
   */
  async getManuais() {
    const {data} = await this.http.get('/manual');
    return data;
  }

  /**
   * Obtem os dados de um manual.
   * @param {number|string} id
   * @returns {Promise<any>}
   */
  async getManual(id) {
    const {data} = await this.http.get('/manual?id=' + id);
    return data;
  }

  /**
   * Insere um manual.
   * @param {string} titulo
   * @param {string} texto
   * @param {array<number>} livros
   * @returns {Promise<void>}
   */
  async insertManual(titulo, texto, livros) {
    const formData = new FormData();
    formData.append('titulo', titulo.trim().toUpperCase());
    formData.append('texto', texto.trim());
    formData.append('livros', JSON.stringify(livros));
    await this.http.post('/manual', formData);
  }

  /**
   * Atualiza um manual.
   * @param {number|string} id
   * @param {string} titulo
   * @param {string} texto
   * @param {array<number>} livros
   * @returns {Promise<void>}
   */
  async updateManual(id, titulo, texto, livros) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('titulo', titulo.trim().toUpperCase());
    formData.append('texto', texto.trim());
    formData.append('livros', JSON.stringify(livros));
    await this.http.put('/manual', formData);
  }

  /**
   * Obtem detalhes de um livro.
   * @param {number|string} id
   * @returns {Promise<any>}
   */
  async getLivro(id) {
    const {data} = await this.http.get('/manual/livro?id=' + id);
    return data;
  }

  /**
   * Obtem a lista de livros.
   * @returns {Promise<any>}
   */
  async getLivros() {
    const {data} = await this.http.get('/manual/livro');
    return data;
  }

  /**
   * Insere um livro.
   * @param {string} nome
   * @returns {Promise<void>}
   */
  async insertLivro(nome) {
    const formData = new FormData();
    formData.append('nome', nome);
    await this.http.post('/manual/livro', formData);
  }

  /**
   * Dá acesso a um livro.
   * @param {string} cpf
   * @param {number|string} livro
   * @returns {Promise<void>}
   */
  async insertLivroAcesso(cpf, livro) {
    const formData = new FormData();
    formData.append('cpf', cpf);
    formData.append('livro', livro.toString());
    await this.http.post('/manual/livro_acesso', formData);
  }

  /**
   * Remove o acesso a um livro.
   * @param {string} cpf
   * @param {number|string} livro
   * @returns {Promise<void>}
   */
  async removeLivroAcesso(cpf, livro) {
    const params = {'cpf': cpf, 'livro': livro.toString()};
    await this.http.delete('/manual/livro_acesso', {params});
  }
}