<template>
  <async-container :loading="loading">
    <v-card>
      <v-card-title>Manual</v-card-title>
      <v-card-text>
        <v-form ref="form-dados" :disabled="dialogLoading">
          <v-text-field
              outlined
              label="Titulo"
              v-model="iptTitulo"
              dense
              hint="O nome deste manual"
              persistent-hint
              :rules="rules.titulo"
          ></v-text-field>
          <v-autocomplete
              :items="meusLivros"
              item-text="nome"
              return-object
              multiple
              v-model="iptLivros"
              chips
              deletable-chips
              small-chips
              outlined
              dense
              label="Livro"
              hint="Em quais livros esse manual estará contido"
              no-data-text="Nenhum livro disponível"
              persistent-hint
              :rules="rules.livros"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <Editor v-model="iptTexto" :init="editorConfig"/>
      <v-card-actions class="justify-center">
        <v-btn color="primary" @click="submitManual">Salvar</v-btn>
        <v-btn color="secondary" to="/manuais">Sair</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialogLoading"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Por favor aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import {loadImageAsync} from '@/plugins/loadImage';
import Editor from '@tinymce/tinymce-vue';
import ManualWebClient from '@/http/ManualWebClient';
export default {
  name: 'Manual',
  components: {AsyncContainer, Editor},
  props: ['id'],
  data: () => ({
    webClient: new ManualWebClient(),
    loading: true,
    dialogLoading: false,
    iptLivros: [],
    iptTitulo: '',
    iptTexto: '',
    editorConfig: {
      language: 'pt_BR',
      plugins: 'code table link lists fullscreen print preview autoresize image',
      menubar: 'edit view table',
      toolbar: 'undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | link image | removeformat print', // fontselect
      contextmenu: 'link fullscreen',
      indentation: '1.2em',
      mobile: {
        theme: 'mobile',
        plugins: ['lists'],
        toolbar: ['undo', 'redo', 'bold', 'italic', 'fontsizeselect', 'underline', 'bullist']
      },
      branding: false,
      images_upload_handler: async (blobInfo, success, failure) => {
        try {
          const blob = blobInfo.blob();
          const canvas = await loadImageAsync(blob, {
            maxWidth: 1280,
            maxHeight: 720,
            canvas: true,
            cover: false,
            orientation: true,
          });
          success(canvas.toDataURL('image/jpeg', .78));
        } catch (e) {
          failure()
        }
      },
    },
    livros: [],
    rules: {
      titulo: [v => !!v || 'Você precisa informar um título'],
      livros: [v => !!v || 'Você precisa mencionar pelo menos 1 livro', v => v.filter(i => i.acesso).length > 0 || 'Você precisa mencionar pelo menos 1 livro'],
    },
  }),
  methods: {
    async loadData() {
      try {
        this.livros = await this.webClient.getLivros();
        if (this.id) {
          const manual = await this.webClient.getManual(this.id);
          this.iptTitulo = manual.titulo;
          this.iptTexto = manual.texto;
          this.iptLivros = this.livros.filter(i => i.manuais.includes(parseInt(this.id)));
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        await this.$router.push('/');
      } finally {
        this.loading = false;
      }
    },
    async submitManual() {
      if (!this.$refs['form-dados'].validate()) return;
      this.dialogLoading = true;
      try {
        if (this.id) await this.webClient.updateManual(this.id, this.iptTitulo, this.iptTexto, this.iptLivros.map(i => i.id));
        else await this.webClient.insertManual(this.iptTitulo, this.iptTexto, this.iptLivros.map(i => i.id));
        this.$router.push('/manuais');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        this.dialogLoading = false;
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    meusLivros() {
      return this.livros.filter(i => i.acesso);
    },
  },
}
</script>

<style scoped>

</style>